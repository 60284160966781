/* General Reset and Box Sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* General RTL settings */
html[dir="rtl"] {
  direction: rtl;  /* Make the layout RTL */
  text-align: right;  /* Right-align text globally */
}

body {
  font-family: Arial, sans-serif;
  background-image: url('../images/background.jpg'); /* Correct path to the background image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; /* הרקע לא יזוז בזמן הגלילה */
  color: var(--primary-text-color); /* Apply primary text color globally */
}


/* Text Colors (Primary and Secondary) */
:root {
  --primary-text-color: #ECEDED; /* Dark gray for primary text */
  --secondary-text-color: green; /* Light gray for secondary text */
  --primary-color: #c98d59; /* Blue for primary actions */
  --secondary-color: blue; /* Green for secondary actions */
  --image-radius: 50%; /* Default round shape */
}


/* ************************  about-us ******************************************** */
.about-us-container {
  max-width: 1200px; /* הגבלת רוחב מקסימלי */
  margin: 0 auto;  /* ממרכז את התוכן */
  padding: 20px;    /* רווח פנימי */
  line-height: 1.6;  /* רווח בין השורות (לקריאות טובה יותר) */
  text-align: center;
}

.about-us-container h1 {
  text-align: center; /* כותרת ממורכזת */
  margin-bottom: 0px; /* רווח מתחת לכותרת */
}

.about-us-container p {
  text-align: justify; /* מרווח את הטקסט כך שכל השורות יסתיימו בקו אחיד */
  line-height: 1.6; /* ריווח בין השורות */
}

/* Container for the title */
.section-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 00px 0;
}

/* Styling for the section title */
.section-title {
  font-size: 2em;
  font-weight: bold;
  margin: 0 20px; /* Add margin between lines and title */
  text-align: center;  /* Centers the text */
}

/* Styling for the lines (before and after the title) */
.line {
  flex: 1; /* Make the lines take equal space on both sides */
  height: 8px; /* Thickness of the line */
  background: linear-gradient(90deg, #91623a, #e2b37d, #c98d59); /* Updated gradient colors */
}



/* ********************  Round Image  ************************************************ */
/* Round Image View with Configurable Radius */
/* ********************  Round Image  ************************************************ */
/* Round Image View with Configurable Radius */
/* ********************  Round Image  ************************************************ */
/* Round Image View with Configurable Radius */
/* ********************  Round Image  ************************************************ */
/* Round Image View with Configurable Radius */
/* ********************  Round Image  ************************************************ */
/* Round Image View with Configurable Radius */


.round-image_admin {
  width: 300px; /* קובע את הרוחב */
  height: 300px; /* קובע את הגובה */
  border-radius: 50%; /* עיגול מלא */
  object-fit: cover; /* וודא שהתמונה תמלא את העיגול מבלי לעוות אותה */
  margin: 10px; /* מרווח בין התמונות */
  border: 8px solid transparent; /* מסגרת שקופה */
  background-image: linear-gradient(90deg, #91623a, #e2b37d, #c98d59); /* Updated gradient colors */

  background-clip: border-box; /* וודא שהגרדיינט נשאר בתוך המסגרת */
  }

.round-image {
  width: 200px; /* קובע את הרוחב */
  height: 200px; /* קובע את הגובה */
  border-radius: 50%; /* עיגול מלא */
  object-fit: cover; /* וודא שהתמונה תמלא את העיגול מבלי לעוות אותה */
  margin: 10px; /* מרווח בין התמונות */
  border: 8px solid transparent; /* מסגרת שקופה */
  background-image: linear-gradient(90deg, #91623a, #e2b37d, #c98d59); /* Updated gradient colors */
  background-clip: border-box; /* וודא שהגרדיינט נשאר בתוך המסגרת */
  border: 5px solid transparent; /* מסגרת שקופה */

}


/* מיכל התמונות */
.images-container-admin {
  display: flex;
  justify-content: space-between; /* מפריד את התמונות לשני הצדדים */
  align-items: center; /* ממרכז את התמונות אנכית בתוך המיכל */
  margin-top: 100px; /* רווח בין הפסקה לתמונות */
  margin-bottom: 200px;
}

/* כל תמונה בתוך המיכל */
.image-wrapper-admin {
  display: flex;
  flex-direction: column; /* ממקם את התמונה והטקסט בצורה אנכית */
  align-items: center; /* ממרכז את התמונה והטקסט בתוך כל תיבה */
  margin: 0px; /* מרווח כללי בין התמונות */
  padding: 0 20px; /* רווח צדדי בתוך כל תיבה */
}

/* טקסט מתחת לכל תמונה */
.image-caption-admin {
  font-size: 1.2em; /* גודל טקסט בינוני */
  margin-top: 10px; /* רווח קטן מעל הטקסט */
  color: var(--primary-text-color); /* Apply primary text color globally */
  padding: 0 15px; /* הוסף רווח צדדי */
  text-align: center; /* ממקם את הטקסט במרכז */
}



/* מיכל התמונות */
.images-container {
  display: flex;
  justify-content: space-between; /* מפריד את התמונות לשני הצדדים */
  align-items: center; /* ממרכז את התמונות אנכית בתוך המיכל */
  margin-top: 100px; /* רווח בין הפסקה לתמונות */
  margin-bottom: 200px;
  flex-wrap: wrap; /* מאפשר למאמנים להתפשט לשורות נוספות */

}

/* כל תמונה בתוך המיכל */
.image-wrapper {
  display: flex;
  flex-direction: column; /* ממקם את התמונה והטקסט בצורה אנכית */
  align-items: center; /* ממרכז את התמונה והטקסט בתוך כל תיבה */
  margin: 0px; /* מרווח כללי בין התמונות */
  padding: 0 20px; /* רווח צדדי בתוך כל תיבה */
  width: calc(25%); /* כל תיבה תתפוס שליש מהשורה, תוך שמירה על רווחים */
  margin-bottom: 50px;

}

/* טקסט מתחת לכל תמונה */
.image-caption {
  font-size: 1.2em; /* גודל טקסט בינוני */
  margin-top: 10px; /* רווח קטן מעל הטקסט */
  color: var(--primary-text-color); /* Apply primary text color globally */
  padding: 0 15px; /* הוסף רווח צדדי */
  text-align: center; /* ממקם את הטקסט במרכז */
}

/* Container for the image and its back side */
.image-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  cursor: pointer; /* Indicates clickable */
}

/* Flip effect when the image is clicked */
.image-container.flipped {
  transform: rotateY(180deg);
}


/* Back side of the flipped image */
.back {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}

/* Optional: Styling for the content on the back of the image */
.back-content {
  padding: 20px;
  font-size: 16px;
  color: black;
  text-align: center;
}

/* Fallback text for when the image cannot be loaded */
.fallback-image {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 18px;
  font-weight: bold;
}



/* מיכל התמונות (במקרה של כוכבים) */
.images-container-stars {
  display: flex;
  justify-content: space-between; /* מפריד את התמונות לשני הצדדים */
  align-items: center; /* ממרכז את התמונות אנכית בתוך המיכל */
  margin-top: 50px; /* רווח בין הפסקה לתמונות */
  margin-bottom: 100px;
  flex-wrap: wrap; /* מאפשר למאמנים להתפשט לשורות נוספות */
}

/* כל תמונה בתוך המיכל (במקרה של כוכבים) */
.image-wrapper-stars {
  display: flex;
  flex-direction: column; /* ממקם את התמונה והטקסט בצורה אנכית */
  align-items: center; /* ממרכז את התמונה והטקסט בתוך כל תיבה */
  margin: 0px; /* מרווח כללי בין התמונות */
  padding: 0 20px; /* רווח צדדי בתוך כל תיבה */
  width: calc(20%); /* כל תיבה תתפוס רבע מהשורה, תוך שמירה על רווחים */
  margin-bottom: 10px;
  perspective: 1000px; /* Adds depth to the 3D effect */

}

/* סגנונות לתמונות כוכבים */
.star-image {
  height: 350px;
  width: auto;
  object-fit: cover;
  transition: transform 0.5s ease-in-out; /* Smooth rotation transition */
}


/* ********************  Header  ************************************************ */
/* Header Styling */
.header-content {
  display: flex;
  justify-content: space-between; /* Space between items */
  align-items: center; /* Center items vertically */
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%; /* Make sure the container takes the full width */
}

/* Logo Styling */
.header-content .logo {
  flex: 1; /* Take equal space */
  text-align: center; /* Center the logo */
  margin-right: auto; /* To center the logo correctly */
  margin-left: auto; /* To center the logo correctly */
}

/* Adjust the image size if needed */
.header-content .logo img {
  height: 280px;
  margin: 0;
  margin-top: 20px;
}

/* Social Icons Styling */
.header-content .social-icons {
  display: flex;
  flex-direction: column; /* Stack icons vertically */
  align-items: end; /* Center horizontally */
  flex: 1; /* Take equal space */
  margin-top: 50px;
}

/* Icon Styling */
.header-content .social-icons a {
  margin-bottom: 15px;
  color: var(--primary-color);
  height: 60px;
  font-size: 35px;
  transition: transform 0.3s ease, filter 0.3s ease;
  margin-left: 50px; /* Adjust this if needed */

}

/* Register Button Styling */
.header-content .register-btn {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex: 1; /* Equal space among sections */
  position: relative;
  margin-left: 0; /* To keep it at the far right */
    justify-content: flex-start; /* Align icons to the left */

}

.header-content .register-btn a {
  color: white;
  text-decoration: none;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  padding: 10px 20px;
  position: relative;
  top: -20px;
  font-size: 26px; /* Increase font size */
  border: 4px solid #c98d59; /* Border with your custom color */

}

.header-content .register-btn a:hover {
  background-color: var(--primary-color); /* Hover color */
}


/* **********************   Footer  ********************************************** */
/* Floating Footer Styling */
/* Floating Footer */
.floating-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: linear-gradient(90deg, #91623a, #e2b37d, #c98d59); /* Updated gradient colors */
  color: white;
  text-align: center;
  padding: 20px 0; /* Increase padding for better spacing */
  font-size: 1.1em; /* Slightly increase the font size for better visibility */
}

.floating-footer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.floating-footer ul li {
  margin: 0 20px; /* Increased spacing between items */
}

.floating-footer ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.9em; /* Slightly increased font size for better visibility */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.floating-footer ul li a.active {
  font-weight: bold;
  text-decoration: underline;
  font-size: 2.1em; /* Slightly increased font size for better visibility */

}

.floating-footer ul li a:hover {
  color: var(--primary-color); /* Hover color (assuming --primary-color is defined) */
}



/* Content Styling */
.content {
  text-align: center;
  padding: 40px;
}




/* CSS עבור האנימציה */
.loading-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: #333;
  text-align: center;
}

/* אם רוצים לספק אנימציה עם ספינר */
.loading-animation::after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.videos-list {
  max-width: 1200px; /* הגבלת רוחב מקסימלי */
  margin: 0 auto;  /* ממרכז את התוכן */
  padding: 2px;    /* רווח פנימי */
  line-height: 1.6;  /* רווח בין השורות (לקריאות טובה יותר) */
  text-align: center;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.video-item {
  width: calc(33% - 10px); /* תלת עמודות */
  margin-bottom: 20px;
  text-align: center;
}

.video-thumbnail {
  overflow: hidden; /* חותך כל תוכן שיוצא מגבולות */
  width: 368px;
  height: 172px;
}

.video-thumbnail iframe {
  width: 100%;
  height: 100%;
  object-fit: cover; /* מוודא שהוידאו יתמלא בתיבה וישתמש בחיתוך אוטומטי */
}

h3 {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 40px;
  font-weight: normal;
}

