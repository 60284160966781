/* Mobile View CSS */
@media only screen and (max-width: 768px) {

  /* General Reset and Box Sizing */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /* Prevent horizontal scrolling */
  body {
    overflow-x: hidden; /* Prevent horizontal scroll */
    font-family: Arial, sans-serif;
    background-image: url('../images/background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: var(--primary-text-color);
  }

  /* General RTL settings for Mobile */
  html[dir="rtl"] {
    direction: rtl;
    text-align: right;
  }

  /* Mobile View for About Us Section */
  .about-us-container {
    max-width: 100%; /* Ensure container width fits the screen */
    margin: 20px auto; /* Add margin at the top and bottom */
    padding: 5px; /* Reduce padding */
    text-align: center;
  }

  .about-us-container h1 {
    font-size: 1.8em; /* Smaller heading size */
    margin-bottom: 15px;
  }

  .about-us-container p {
    font-size: 0.8em; /* Adjust paragraph font size */
    line-height: 1.4;
    text-align: justify;

  }

  .section-title {
    font-size: 1.5em; /* Reduce font size for smaller screens */
    margin: 10px 0;
  }

.section-title::before,
.section-title::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 0%; /* גודל הקווים */
  height: 8px; /* עובי הקו */
  background: linear-gradient(90deg, #FFD700, #FF6347); /* גרדיינט זהב */
  transform: translateY(-50%);
}

.section-title::before {
  left: -50%; /* המרחק מימין לטקסט */
}

.section-title::after {
  right: -50%; /* המרחק משמאל לטקסט */
}

.image-wrapper {
  display: flex;
  flex-direction: column; /* ממקם את התמונה והטקסט בצורה אנכית */
  align-items: center; /* ממרכז את התמונה והטקסט בתוך כל תיבה */
  margin: 0px; /* מרווח כללי בין התמונות */
  padding: 0 0px; /* רווח צדדי בתוך כל תיבה */
  width: calc(33.33%); /* כל תיבה תתפוס שליש מהשורה, תוך שמירה על רווחים */
  margin-bottom: 50px;

}

/* כל תמונה בתוך המיכל */
.image-wrapper-admin {
  display: flex;
  flex-direction: column; /* ממקם את התמונה והטקסט בצורה אנכית */
  align-items: center; /* ממרכז את התמונה והטקסט בתוך כל תיבה */
  margin: 0px; /* מרווח כללי בין התמונות */
  padding: 0 5px; /* רווח צדדי בתוך כל תיבה */
}

/* טקסט מתחת לכל תמונה */
.image-caption-admin {
  font-size: 1.0em; /* גודל טקסט בינוני */
  margin: 15px; /* רווח קטן מעל הטקסט */
  color: var(--primary-text-color); /* Apply primary text color globally */
}

  .round-image_admin {
    width: 150px; /* Smaller width */
    height: 150px; /* Smaller height */
    border-radius: 50%; /* Keep the round shape */
    margin: 3px; /* Reduce margin to avoid excessive spacing */
    border: 4px solid transparent; /* מסגרת שקופה */

  }

  .round-image {
    width: 80px; /* Smaller width */
    height: 80px; /* Smaller height */
    border-radius: 50%; /* Keep the round shape */
    margin: 0px; /* Reduce margin to avoid excessive spacing */
    border: 2px solid transparent; /* מסגרת שקופה */

  }

  /* Optional: Adjust other elements like text captions */
  .image-caption {
    font-size: 1em; /* Smaller font size for captions */
    padding: 0 1px; /* Reduce padding for better fit */
  }
/****************************************************************************
/****************************************************************************
/****************************************************************************
/****************************************************************************
/****************************************************************************


/* כל תמונה בתוך המיכל (במקרה של כוכבים) */
.image-wrapper-stars {
  flex-direction: column;
  align-items: center;
  width: calc(25%);
  margin-bottom: 50px;
}

/* Image styling */
.star-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  backface-visibility: hidden; /* Hide image back side during flip */
}


  .video-item {
    width: calc(50% - 10px); /* תצוגה של 2 סרטונים בשורה */
  }

  .video-thumbnail {
    width: 100%; /* שים את הרוחב של התמונה ל-100% */
    height: auto; /* שימור יחס גובה-רוחב של התמונה */
  }






  /* Header Adjustments for Mobile */

/* ********************  Header  ************************************************ */
    /* Header Styling */
    .header-content {
      display: flex;
      justify-content: space-between; /* Space between items */
      align-items: center; /* Center items vertically */
      text-align: center; /* Align text in the center */
      padding: 0px;
      max-width: 1000px;
      margin: 0 auto;
    }

    /* Logo Styling */
    .header-content .logo {
      flex: 1; /* Take equal space */
      text-align: center; /* Center the logo */
    }

    .header-content .logo img {
      height: 150px;
      margin: 0; /* Remove top margin for better centering */
      margin-top: 20px; /* You can adjust the value as needed */
    }
/* Social Icons Styling */
.header-content .social-icons {
  display: flex; /* Display as a flex container */
  flex-direction: column; /* Stack icons vertically */
  align-items: flex-end; /* Align the icons to the left */
  flex: 1; /* Take equal space */
  margin-top: 50px; /* You can adjust the value as needed */
}

.header-content .social-icons a {
  margin-bottom: 15px; /* Space between icons */
  color: var(--primary-color); /* Use primary color for icons */
  height: 30px; /* Set the height of icons */
  font-size: 25px; /* Set font size for icons */
  display: flex; /* Make each link a flex container */
  justify-content: flex-end; /* Align the icon to the left */
  align-items: center; /* Center the icon vertically within its container */
}




.header-content .register-btn a {
  border-radius: 20px;
  transition: background-color 0.3s ease;
  padding: 10px 20px;
  position: relative;
  top: -20px;
  font-size: 14px; /* Increase font size */
  border: 2px solid #c98d59; /* Border with your custom color */

}


  /* Floating Footer Adjustments for Mobile */
  .floating-footer {
    font-size: 1.1em; /* Reduce font size */
    padding: 8px 0;
  }

  .floating-footer ul li {
    display: inline-block;
    margin: 0 10px; /* Adjust spacing */
  }

  .floating-footer ul li a {
    font-size: 1em; /* Adjust font size for links */
  }

  .floating-footer ul li a:hover {
    text-decoration: underline;
  }

.floating-footer ul li a.active {
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.2em; /* Slightly increased font size for better visibility */

}

  /* Content Styling for Mobile */
  .content {
    padding: 20px; /* Adjust padding */
    font-size: 1.1em; /* Adjust font size */
  }



  /* Ensure no content overflows */
  .about-us-container, .header-content, .floating-footer {
    max-width: 100%; /* Ensure these containers do not exceed the screen width */
    overflow-x: hidden; /* Prevent horizontal overflow */
  }
}
